import React, { useState } from "react";
import axios from "axios";
import { url } from "../link";
import Navbar from "../components/Navbar";
import { error } from "jquery";

const Message = () => {
  const [response, setResponse] = useState(null);
  const [message, setMessage] = useState("");
  const [mobile, setMobile] = useState("");

  const sendSMS = async () => {
    const data = {
      mobile: mobile.split(","),
      message: message,
    };
    axios
      .post(url + "/edusphere/sendsmsall", data)
      .then((res) => {
        setResponse(res.data);
        setMessage("");
        setMobile("");
      })
      .catch((error) => {
        setResponse({ error: "Error sending SMS" });
      });
  };

  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <div className="card">
          <div className="card-header">
            <h3 className="text-center">
              Send Whatsapp to Multiple Recipients
            </h3>
          </div>
          <div className="card-body">
            <form>
              <div className="mb-3">
                <label htmlFor="mobile" className="form-label">
                  Mobile Numbers (comma separated)
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="mobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  placeholder="Enter mobile numbers"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  rows="4"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Enter your message"
                ></textarea>
              </div>
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={sendSMS}
              >
                Send SMS
              </button>
            </form>
          </div>
          <div className="card-footer">
            {response && (
              <div className="alert alert-info mt-3">
                <pre>{JSON.stringify(response, null, 2)}</pre>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Message;
