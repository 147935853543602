import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { url } from "../link";
const Viewlaptop = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [value, setvalue] = useState([]);
  useEffect(() => {
    axios.post(url + "/edusphere/viewlaptop").then((response) => {
      setData(response.data);
      setvalue(response.data);
    });
  }, []);
  const viewlaptop = (e) => {
    nav("/updatelaptop", { state: e });
  };
  const deletec = (e) => {
    axios.post(url + "/edusphere/deletelaptop", { id: e }).then((response) => {
      axios.post(url + "/edusphere/viewlaptop").then((response) => {
        setData(response.data);
        setvalue(response.data);
      });
    });
  };
  const searchdata = (e) => {
    const r = [];

    for (var k of value) {
      var v = 0;

      for (var n of k) {
        n = "" + n;
        if (n.toLowerCase().indexOf(e) !== -1) {
          v = 1;
          break;
        }
      }
      if (v === 1) {
        r.push(k);
      }
    }
    setData(r);
  };

  return (
    <div>
      <Navbar />
      <h3>laptop</h3>
      <input
        type="search"
        onChange={(e) => searchdata(e.target.value)}
        className="form-select"
        placeholder="Search"
      />
      <div className="table-responsive">
        <table className="table table-bordered" id="table_id">
          <thead>
            <tr>
              <th>lid</th>
              <th>laptopname</th>
              <th>addons</th>
              <th>givendate</th>
              <th>deliverydate</th>
              <th>commiment</th>
              <th>pid</th>
              <th>receivedby</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((d) => {
              var x = `${url}/static/${d[9]}`;
              return (
                <tr key={d[0]}>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => viewlaptop(d)}
                    >
                      {d[0]}
                    </button>
                  </td>
                  <td>{d[1]}</td>
                  <td>{d[2]}</td>
                  <td>{d[3]}</td>
                  <td>{d[4]}</td>
                  <td>{d[5]}</td>
                  <td>{d[6]}</td>
                  <td>{d[7]}</td>
                  <td>{d[8]}</td>
                  {d[10] !== null ? (
                    <td>
                      <img src={x} alt="" width="100px" height="100px" />
                    </td>
                  ) : (
                    <td></td>
                  )}
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => deletec(d[0])}
                    >
                      delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Viewlaptop;
