import axios from "axios";
import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Nav from "../process/Nav";
import { url } from "../link";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Loading from "../components/Loading";

const Viewtodayattendance = () => {
  var staffid = window.localStorage.getItem("user").split(",")[1];
  const [x, setx] = useState(0);
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [value, setValue] = useState([]);
  const [aids, setaids] = useState([]);
  const d = ["Hariharan", "Rajasekar", "Rajesh"];
  const [taid, setTaid] = useState("");
  const [aid, setaid] = useState("");
  const [days, setDays] = useState([
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ]);

  const [classbyFilter, setClassbyFilter] = useState(
    window.localStorage.getItem("user").split(",")[1]
  );
  const [selectedDays, setSelectedDays] = useState([]);
  const [student, setStudent] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState(null);
  const [longLeaveModalShow, setLongLeaveModalShow] = useState(false);
  const [longLeaveAttendance, setLongLeaveAttendance] = useState(null);
  const [updateClassbyModalShow, setUpdateClassbyModalShow] = useState(false);
  const [selectedClassbyAttendance, setSelectedClassbyAttendance] =
    useState(null);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const reorderedDays = Array.from(days);
    const [removed] = reorderedDays.splice(source.index, 1);
    reorderedDays.splice(destination.index, 0, removed);

    setDays(reorderedDays);
  };

  const filterByClassby = (e) => {
    setClassbyFilter(e);
    if (e) {
      const filteredData = value.filter((item) => item[5] === e);
      setData(filteredData);
    } else {
      setData(value);
    }
  };

  const addSelectedDay = (day) => {
    if (!selectedDays.includes(day)) {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const removeSelectedDay = (day) => {
    setSelectedDays(selectedDays.filter((d) => d !== day));
  };

  const [eid, seteid] = useState("");
  const [starttiming, setstarttiming] = useState("");
  const [endtime, setendtime] = useState("");

  useEffect(() => {
    axios.post(url + "/edusphere/searchstudent").then((response) => {
      setStudent(response.data);
    });
    axios.post(url + "/edusphere/viewtodayattendance").then((response) => {
      const filteredData = response.data.filter((item) => item[5] === staffid);
      setData(filteredData);
      setValue(response.data);
      setx(1);
    });
  }, []);

  const deletec = (e) => {
    setx(0);

    axios
      .post(url + "/edusphere/deleteattendance", { id: e })
      .then((response) => {
        const filteredData = data.filter((item) => {
          return item[5] === staffid && item[0] !== e;
        });

        setData(filteredData);
        setValue(filteredData);
        setx(1);
      })
      .catch((res) => {
        setx(1);
      });
  };

  const searchdata = (e) => {
    var e = e.toLowerCase();
    const r = [];
    for (var k of value) {
      var v = 0;
      for (var n of k) {
        n = "" + n;
        if (n.toLowerCase().indexOf(e) !== -1) {
          v = 1;
          break;
        }
      }
      if (v === 1) {
        r.push(k);
      }
    }
    setData(r);
  };

  const addTodayAttendance = () => {
    if (eid != null && selectedDays.length !== 0) {
      setx(0);
      const value = {
        eid: eid,
        starttiming: starttiming,
        endtime: endtime,
        skiptilldate: "",
        status: "attending",
        selectedDays: selectedDays,
      };
      axios
        .post(url + "/edusphere/insertattendance", value)
        .then((res) => {
          alert("success");
          seteid("");
          setstarttiming("");
          setendtime("");
          setSelectedDays([]);

          axios
            .post(url + "/edusphere/viewtodayattendance")
            .then((response) => {
              const filteredData = response.data.filter(
                (item) => item[5] === staffid
              );
              setData(filteredData);
              setValue(response.data);
              setx(1);
            });
        })
        .catch((res) => {
          setx(1);
        });
    }
  };
  const [mobile, setmobile] = useState("");
  const handleMarkAttendance = (attendance, mobile) => {
    setSelectedAttendance(attendance);
    setmobile(mobile);
    setModalShow(true);
  };

  const handleSaveAttendance = (aid, status, remark) => {
    setx(0);
    const value = { aid: aid, status: status, remark: remark, mobile: mobile };
    axios
      .post(url + "/edusphere/inserttodayattendance", value)
      .then((res) => {
        alert("Attendance marked successfully");
        setModalShow(false);
        setSelectedAttendance(null);
        const filteredData = data.filter((item) => {
          return item[5] === staffid && item[0] !== aid;
        });

        setData(filteredData);
        setValue(filteredData);
        setx(1);
      })
      .catch((res) => {
        setx(1);
      });
  };

  const handleLongLeave = (attendance) => {
    setLongLeaveAttendance(attendance);
    setLongLeaveModalShow(true);
  };

  const handleSaveLongLeave = (aid, endDate, startDate) => {
    setx(0);
    const value = {
      aid: aid,
      endDate: endDate,
      startdate: startDate,
    };
    axios
      .post(url + "/edusphere/updateLongLeave", value)
      .then((res) => {
        alert("Long leave updated successfully");
        setLongLeaveModalShow(false);
        setLongLeaveAttendance(null);
        const filteredData = data.filter((item) => {
          return item[5] === staffid && item[0] !== aid;
        });

        setData(filteredData);
        setValue(filteredData);
        setx(1);
      })
      .catch((res) => {
        setx(1);
      });
  };
  const [cl, setcl] = useState();
  const handleUpdateClassby = (attendance, x) => {
    setSelectedClassbyAttendance(attendance);
    setcl(x);
    setUpdateClassbyModalShow(true);
  };

  const handleSaveClassby = (aid, classby) => {
    const value = { aid: aid, classby: classby };
    setx();
    axios
      .post(url + "/edusphere/updateClassby", value)
      .then((res) => {
        alert("Classby updated successfully");
        setUpdateClassbyModalShow(false);
        setSelectedClassbyAttendance(null);
        const filteredData = data.filter((item) => {
          return item[5] === staffid && item[0] !== aid;
        });

        setData(filteredData);
        setValue(filteredData);
        setx(1);
      })
      .catch((err) => {
        setx(1);
      });
  };
  const contents = () => {
    if (x === 0) {
      return <Loading />;
    } else {
      return (
        <div>
          <Nav />
          <h3>Today's Attendance</h3>
          <h5>{data.length}</h5>
          <input
            type="search"
            onChange={(e) => searchdata(e.target.value)}
            className="form-select"
            placeholder="Search"
          />
          <div className="mb-3">
            <label htmlFor="classbyFilter" className="form-label">
              Filter by Classby
            </label>
            <select
              id="classbyFilter"
              className="form-select"
              value={classbyFilter}
              onChange={(e) => {
                filterByClassby(e.target.value);
              }}
            >
              <option value="">All</option>
              {d.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <button
            type="button"
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#addAttendanceModal"
          >
            Add Today Attendance
          </button>
          <NavLink to="/viewattendance">
            <button type="button" className="btn btn-danger">
              View All Attendance
            </button>
          </NavLink>

          <NavLink to="/viewattendancetoday">
            <button type="button" className="btn btn-danger">
              View Today Attendance
            </button>
          </NavLink>
          <div
            className="modal fade"
            id="addAttendanceModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add Today Attendance
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="mb-3">
                    <label htmlFor="taid" className="form-label">
                      Userid
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="taid"
                      value={taid}
                      onChange={(e) => {
                        var k = e.target.value;
                        var n = k.split("-")[0];

                        setTaid(k);
                        seteid(n);
                      }}
                      list="taidOptions"
                    />
                    <datalist id="taidOptions">
                      {student &&
                        student.map((s) => {
                          return <option key={s}>{s}</option>;
                        })}
                    </datalist>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="aid" className="form-label">
                      RID
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="aid"
                      value={eid}
                      onChange={(e) => seteid(e.target.value)}
                      readOnly
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="status" className="form-label">
                      Starttime
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      id="status"
                      value={starttiming}
                      onChange={(e) => setstarttiming(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="remark" className="form-label">
                      Endtiming
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      id="remark"
                      value={endtime}
                      onChange={(e) => setendtime(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Select Days of the Week</label>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="days">
                      {(provided) => (
                        <ul
                          className="list-group"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {days.map((day, index) => (
                            <Draggable
                              key={day}
                              draggableId={day}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  className="list-group-item"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {day}
                                  <button
                                    type="button"
                                    className="btn btn-secondary btn-sm float-end"
                                    onClick={() => addSelectedDay(day)}
                                  >
                                    Add
                                  </button>
                                </li>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                <div className="mb-3">
                  <label className="form-label">Selected Days</label>
                  <ul className="list-group">
                    {selectedDays.map((day) => (
                      <li className="list-group-item" key={day}>
                        {day}
                        <button
                          type="button"
                          className="btn btn-danger btn-sm float-end"
                          onClick={() => removeSelectedDay(day)}
                        >
                          Remove
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={addTodayAttendance}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => {
              setx(0);
              var k = data.filter((dx) => {
                return dx[5] === staffid;
              });
              axios
                .post(url + "/edusphere/endattendance", { r: k })
                .then((res) => {
                  axios
                    .post(url + "/edusphere/viewtodayattendance")
                    .then((response) => {
                      alert("attendance marked Successfully");
                      setData(response.data);
                      setValue(response.data);
                      setx(1);
                    });
                });
            }}
          >
            End Attendance
          </button>
          <button
            type="button"
            className="btn btn-warning"
            data-bs-toggle="modal"
            data-bs-target="#editAttendanceModal"
          >
            Edit Attendance
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => {
              setx(0);
              axios
                .post(url + "/edusphere/inserttodayattendancebulk", {
                  data: aids,
                  status: "present",
                })
                .then((response) => {
                  axios
                    .post(url + "/edusphere/viewtodayattendance")
                    .then((response) => {
                      const filteredData = response.data.filter(
                        (item) => item[5] === staffid
                      );
                      setData(filteredData);
                      setValue(response.data);
                      setx(1);
                    });
                });
            }}
          >
            Bulk Present
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => {
              setx(0);
              axios
                .post(url + "/edusphere/inserttodayattendancebulk", {
                  data: aids,
                  status: "present",
                })
                .then((response) => {
                  axios
                    .post(url + "/edusphere/viewtodayattendance")
                    .then((response) => {
                      const filteredData = response.data.filter(
                        (item) => item[5] === staffid
                      );
                      setData(filteredData);
                      setValue(response.data);
                      setx(1);
                    });
                });
            }}
          >
            Bulk Absent
          </button>
          <div className="table-responsive">
            <table className="table table-bordered" id="table_id">
              <thead>
                <tr>
                  <th></th>
                  <th>Rid</th>
                  <th>Name</th>
                  <th>Cname</th>
                  <th>Mobile</th>
                  <th>Classby</th>
                  <th>Startime</th>
                  <th>Endtime</th>
                  <th>Course</th>
                  <th>Status</th>
                  <th>Days</th>
                  <th>Balance</th>
                  <th>Next Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((d) => {
                  return (
                    <tr key={d[0]}>
                      <td>
                        <input
                          type="checkbox"
                          onChange={() => {
                            let index = aids.findIndex(
                              (aidObj) =>
                                aidObj.aid === d[0] && aidObj.mobile === d[4]
                            ); // find the index of the element

                            if (index !== -1) {
                              aids.splice(index, 1); // remove the element at the index
                            } else {
                              aids.push({ aid: d[0], mobile: d[4] });
                            }

                            setaids([...aids]); // use a spread operator to ensure state updates properly
                          }}
                        />
                      </td>
                      <td>{d[1]}</td>
                      <td>{d[2]}</td>
                      <td>{d[3]}</td>
                      <td>{d[4]}</td>
                      <td>{d[5]}</td>
                      <td>{d[6]}</td>
                      <td>{d[7]}</td>
                      <td>{d[9]}</td>
                      <td>{d[8]}</td>
                      <td>{d[10]}</td>
                      <td>{d[12]}</td>
                      <td>{d[13]}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => deletec(d[0])}
                        >
                          Delete Attendance
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleMarkAttendance(d[0], d[4])}
                        >
                          Mark Attendance
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => handleLongLeave(d[0])}
                        >
                          Long Leave
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => handleUpdateClassby(d[0], d[5])}
                        >
                          Update Classby
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div
            className="modal fade"
            id="editAttendanceModal"
            tabIndex="-1"
            aria-labelledby="editModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="editModalLabel">
                    Edit Attendance
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="mb-3">
                    <label htmlFor="editTaid" className="form-label">
                      RID
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="editTaid"
                      value={eid}
                      onChange={(e) => {
                        seteid(e.target.value);
                      }}
                    />
                    <button
                      className="btn btn-outline-success"
                      onClick={() => {
                        axios
                          .post(url + "/edusphere/viewattendanceinfo", {
                            aid: eid,
                          })
                          .then((res) => {
                            if (res.data !== null) {
                              var attendance = res.data["attendance"];
                              var days = res.data["days"];
                              setaid(attendance[0]);
                              setstarttiming(attendance[2]);
                              setendtime(attendance[3]);
                              const x = days.map((d) => {
                                return d[2];
                              });
                              setSelectedDays(x);
                            } else {
                              alert("not found");
                            }
                          })
                          .catch((err) => {
                            alert("error");
                          });
                      }}
                    >
                      check
                    </button>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="editStartTime" className="form-label">
                      Starttime
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      id="editStartTime"
                      value={starttiming}
                      onChange={(e) => setstarttiming(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="editEndTime" className="form-label">
                      Endtiming
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      id="editEndTime"
                      value={endtime}
                      onChange={(e) => setendtime(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Select Days of the Week
                    </label>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="editDays">
                        {(provided) => (
                          <ul
                            className="list-group"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {days.map((day, index) => (
                              <Draggable
                                key={day}
                                draggableId={day}
                                index={index}
                              >
                                {(provided) => (
                                  <li
                                    className="list-group-item"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {day}
                                    <button
                                      type="button"
                                      className="btn btn-secondary btn-sm float-end"
                                      onClick={() => addSelectedDay(day)}
                                    >
                                      Add
                                    </button>
                                  </li>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </ul>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Selected Days</label>
                    <ul className="list-group">
                      {selectedDays.map((day) => {
                        return (
                          <li className="list-group-item" key={day}>
                            {day}
                            <button
                              type="button"
                              className="btn btn-danger btn-sm float-end"
                              onClick={() => removeSelectedDay(day)}
                            >
                              Remove
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      if (eid != null && selectedDays.length !== 0) {
                        const value = {
                          aid: aid,
                          eid: eid,
                          starttiming: starttiming,
                          endtime: endtime,
                          skiptilldate: "",
                          status: "attending",
                          selectedDays: selectedDays,
                        };
                        axios
                          .post(url + "/edusphere/editattendance", value)
                          .then((res) => {
                            alert("success");
                            seteid("");
                            setstarttiming("");
                            setendtime("");
                            setSelectedDays([]);

                            axios
                              .post(url + "/edusphere/viewtodayattendance")
                              .then((response) => {
                                const filteredData = response.data.filter(
                                  (item) => item[5] === staffid
                                );
                                setData(filteredData);
                                setValue(response.data);
                              });
                          })
                          .catch((res) => {});
                      }
                    }}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>

          <MarkAttendanceModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            onSave={handleSaveAttendance}
            attendance={selectedAttendance}
          />

          <LongLeaveModal
            show={longLeaveModalShow}
            onHide={() => setLongLeaveModalShow(false)}
            onSave={handleSaveLongLeave}
            attendance={longLeaveAttendance}
          />

          <UpdateClassbyModal
            show={updateClassbyModalShow}
            onHide={() => setUpdateClassbyModalShow(false)}
            onSave={handleSaveClassby}
            attendance={selectedClassbyAttendance}
            cl={cl}
          />
        </div>
      );
    }
  };

  return <>{contents()}</>;
};

const MarkAttendanceModal = ({ show, onHide, onSave, attendance }) => {
  const [status, setStatus] = useState("present");
  const [remark, setRemark] = useState("");

  const handleSave = () => {
    onSave(attendance, status, remark);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Mark Attendance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-floating mb-3 mt-3">
          <select
            className="form-select"
            onChange={(e) => setStatus(e.target.value)}
            value={status}
            placeholder="Enter status"
          >
            <option>present</option>
            <option>informed leave</option>
            <option>leave</option>
          </select>
          <label htmlFor="status">Status</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
            placeholder="Enter remark"
          />
          <label htmlFor="remark">Remark</label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const LongLeaveModal = ({ show, onHide, onSave, attendance }) => {
  const [startDate, setstartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleSave = () => {
    onSave(attendance, endDate, startDate);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Long Leave</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-floating mb-3 mt-3">
          <input
            type="date"
            className="form-control"
            onChange={(e) => setEndDate(e.target.value)}
            value={endDate}
            placeholder="Enter Start date"
          />
          <label htmlFor="endDate">Start Date</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="date"
            className="form-control"
            onChange={(e) => setstartDate(e.target.value)}
            value={startDate}
            placeholder="Enter end date"
          />
          <label htmlFor="endDate">End Date</label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const UpdateClassbyModal = ({ show, onHide, onSave, attendance, cl }) => {
  const [classby, setClassby] = useState(cl);
  const d = ["Hariharan", "Rajasekar", "Rajesh"];

  const handleSave = () => {
    onSave(attendance, classby);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Update Classby</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-floating mb-3 mt-3">
          <select
            className="form-control"
            onChange={(e) => setClassby(e.target.value)}
            value={classby}
            placeholder="Enter classby"
          >
            {d.map((r) => {
              return <option key={r}>{r}</option>;
            })}
          </select>
          <label htmlFor="classby">Classby</label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default Viewtodayattendance;
