import Updatesubject from "./books/updatesubject";
import Viewsubject from "./books/viewsubject";
import Addsubject from "./books/addsubject";
import Updatetopics from "./books/updatetopics";
import Viewtopics from "./books/viewtopics";
import Addtopics from "./books/addtopics";
import Updatesubtopics from "./books/updatesubtopics";
import Viewsubtopics from "./books/viewsubtopics";
import Addsubtopics from "./books/addsubtopics";
import Updatecontent from "./books/updatecontent";
import Viewcontent from "./books/viewcontent";
import Addcontent from "./books/addcontent";
import Oldstudent from "./process/oldstudent";
import Updateregistration from "./process/updateregistration";
import Viewregistration from "./process/viewregistration";
import Addregistration from "./process/addregistration";
import Updateproject from "./project/updateproject";
import Viewproject from "./project/viewproject";
import Addproject from "./project/addproject";
import Viewbill from "./process/viewbill";
import Addbill from "./process/addbill";
import Enquiryentry from "./process/Enquiryentry";
import Viewenquiry from "./process/Viewenquiry";
import { Route, Routes } from "react-router-dom";
import Showenquiry from "./process/Showenquiry";
import Updatecoursecat from "./process/updatecoursecat";
import Viewcoursecat from "./process/viewcoursecat";
import Addcoursecat from "./process/addcoursecat";
import Pendingbal from "./process/pendingbal";
import Openenquiry from "./process/openenquiry";
import Updateenquiry from "./process/Updateenquiry";
import Viewsinglereg from "./process/viewsinglereg";
import Login from "./login/Login";
import Editprofile from "./process/Editprofile";

import Checkexam from "./exam/Checkexam";

import Updatequestion from "./question/updatequestion";
import Viewquestion from "./question/viewquestion";
import Addquestion from "./question/addquestion";
import Bulkquestion from "./question/bulkquestion";

import Sqlserver from "./course/sqlserver";
import C from "./course/c";
import Cpp from "./course/cpp";
import Cs from "./course/cs";
import Datascience from "./course/datascience";
import Django from "./course/django";
import Ds from "./course/ds";
import Flask from "./course/flask";
import Hibernate from "./course/hibernate";
import Html from "./course/html";
import Java from "./course/java";
import Javafullstack from "./course/javafullstack";
import Javawebdevelopment from "./course/javawebdevelopment";
import Jsp from "./course/jsp";
import Mongodb from "./course/mongodb";
import Msoffice from "./course/msoffice";
import Mysql from "./course/mysql";
import Python from "./course/python";
import Pythonwebdevelopment from "./course/pythonwebdevelopment";
import Reactjs from "./course/reactjs";
import Selenium from "./course/selenium";
import Springboot from "./course/springboot";
import Tallyprime from "./course/tallyprime";
import Advexcel from "./course/advexcel";
import Printbill from "./process/printbill";
import Home from "./process/home";
import Updateexpense from "./expense/updateexpense";
import Viewexpense from "./expense/viewexpense";
import Addexpense from "./expense/addexpense";
import Updateamountavailble from "./expense/updateamountavailble";
import Viewamountavailble from "./expense/viewamountavailble";
import Addamountavailble from "./expense/addamountavailble";

import Updatemonthlyexpense from "./expense/updatemonthlyexpense";
import Viewmonthlyexpense from "./expense/viewmonthlyexpense";
import Addmonthlyexpense from "./expense/addmonthlyexpense";
import Dashboard from "./expense/Dashboard";
import Check from "./process/check";
import Schedule from "./exam/schedule";
import Viewexam from "./exam/viewexam";
import Attendexam from "./exam/attendexam";
import Examwindow from "./exam/examwindow";
import Startexam from "./exam/startexam";
import Exam from "./exam/exam";

import Updateproject2023 from "./newchanges/updateproject2023";
import Viewproject2023 from "./newchanges/viewproject2023";
import Addproject2023 from "./newchanges/addproject2023";
import Updateproject23payment from "./newchanges/updateproject23payment";
import Viewproject23payment from "./newchanges/viewproject23payment";
import Addproject23payment from "./newchanges/addproject23payment";
import Updateregistrationremark from "./newchanges/updateregistrationremark";
import Viewregistrationremark from "./newchanges/viewregistrationremark";
import Addregistrationremark from "./newchanges/addregistrationremark";
import Updateenquiryremark from "./newchanges/updateenquiryremark";
import Viewenquiryremark from "./newchanges/viewenquiryremark";
import Addenquiryremark from "./newchanges/addenquiryremark";
import Updatemobileenquiry from "./mobile/updatemobileenquiry";
import Viewmobileenquiry from "./mobile/viewmobileenquiry";
import Addmobileenquiry from "./mobile/addmobileenquiry";
import Afterexam from "./exam/Afterexam";

import "./App.css";
import Viewdailyreport from "./newchanges/Viewdailyreport";
import Coursechange from "./process/Coursechange";

import Updatemobileremark from "./mobile/updatemobileremark";
import Viewmobileremark from "./mobile/viewmobileremark";
import Addmobileremark from "./mobile/addmobileremark";

// attendance
import Updateattendance from "./attendance/updateattendance";
import Viewattendance from "./attendance/viewattendance";
import Addattendance from "./attendance/addattendance";
import Updateattendingdays from "./attendance/updateattendingdays";
import Viewattendingdays from "./attendance/viewattendingdays";
import Addattendingdays from "./attendance/addattendingdays";
import Updatetodayattendance from "./attendance/updatetodayattendance";
import Viewtodayattendance from "./attendance/viewtodayattendance";

import Viewattendancetoday from "./attendance/viewattendancetoday";
import Addtodayattendance from "./attendance/addtodayattendance";
import Updatestaffattendance from "./staffattendance/updatestaffattendance";
import Viewstaffattendance from "./staffattendance/viewstaffattendance";
import Addstaffattendance from "./staffattendance/addstaffattendance";

import Updatelaptop from "./newchanges/updatelaptop";
import Viewlaptop from "./newchanges/viewlaptop";
import Addlaptop from "./newchanges/addlaptop";
import Updatelaptopphoto from "./newchanges/updatelaptopphoto";
import Viewlaptopphoto from "./newchanges/viewlaptopphoto";
import Addlaptopphoto from "./newchanges/addlaptopphoto";

import Updateprojectcommentment from "./newchanges/updateprojectcommentment";
import Viewprojectcommentment from "./newchanges/viewprojectcommentment";
import Addprojectcommentment from "./newchanges/addprojectcommentment";
import Viewprojectcommit from "./newchanges/viewprojectcommit";

// my works
import Course_page from "./course_design/course_main";
import Homepage from "./course_design/course_home";
import Only_contact from "./course_design/only_contact_page";
import Only_about from "./course_design/only_about";
// import Enquirypage from "./enquiry/Enquiryentry";

import Enquiryentryforall from "./process/Enquiryentryforall";
import Message from "./process/Message";
const App = () => {
  return (
    <>
      <Routes>
        {/* my works */}
        <Route path="/message" element={<Message />} />
        <Route path="/checkexam" element={<Checkexam />} />

        <Route path="/course_check" element={<Course_page />} />
        <Route path="/" element={<Homepage />} />
        <Route path="about" element={<Only_about />} />
        <Route path="/contact" element={<Only_contact />} />

        {/* ends */}
        <Route path="/viewattendancetoday" element={<Viewattendancetoday />} />
        <Route
          path="/updateprojectcommentment"
          element={<Updateprojectcommentment />}
        />
        <Route
          path="/viewprojectcommentment"
          element={<Viewprojectcommentment />}
        />
        <Route
          path="/addprojectcommentment"
          element={<Addprojectcommentment />}
        />

        <Route path="/updatelaptopphoto" element={<Updatelaptopphoto />} />
        <Route path="/viewlaptopphoto" element={<Viewlaptopphoto />} />
        <Route path="/addlaptopphoto" element={<Addlaptopphoto />} />

        <Route path="/updatelaptop" element={<Updatelaptop />} />
        <Route path="/viewlaptop" element={<Viewlaptop />} />
        <Route path="/addlaptop" element={<Addlaptop />} />
        <Route
          path="/updatestaffattendance"
          element={<Updatestaffattendance />}
        />
        <Route path="/viewstaffattendance" element={<Viewstaffattendance />} />
        <Route path="/addstaffattendance" element={<Addstaffattendance />} />
        {/* attendance */}
        <Route path="/updateattendance" element={<Updateattendance />} />
        <Route path="/viewattendance" element={<Viewattendance />} />
        <Route path="/addattendance" element={<Addattendance />} />

        <Route path="/updateattendingdays" element={<Updateattendingdays />} />
        <Route path="/viewattendingdays" element={<Viewattendingdays />} />
        <Route path="/addattendingdays" element={<Addattendingdays />} />

        <Route
          path="/updatetodayattendance"
          element={<Updatetodayattendance />}
        />
        <Route path="/viewtodayattendance" element={<Viewtodayattendance />} />
        <Route path="/addtodayattendance" element={<Addtodayattendance />} />

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/afterexam" element={<Afterexam />} />
        <Route path="/exam" element={<Exam />} />
        <Route path="/examwindow" element={<Examwindow />} />
        <Route path="/scheduleexam" element={<Schedule />} />
        <Route path="/viewexam" element={<Viewexam />} />
        <Route path="/attendexam" element={<Attendexam />} />
        <Route path="/startexam" element={<Startexam />} />

        <Route path="/updatemobileremark" element={<Updatemobileremark />} />
        <Route path="/viewmobileremark" element={<Viewmobileremark />} />
        <Route path="/addmobileremark" element={<Addmobileremark />} />
        <Route
          path="/updateamountavailble"
          element={<Updateamountavailble />}
        />
        <Route path="/viewamountavailble" element={<Viewamountavailble />} />
        <Route path="/addamountavailble" element={<Addamountavailble />} />

        <Route path="/showenquiry" element={<Showenquiry />} />
        <Route path="/login" element={<Login />} />
        <Route path="/viewenquiry" element={<Viewenquiry />} />
        <Route path="/openenquiry" element={<Openenquiry />} />
        <Route path="/updateenquiry" element={<Updateenquiry />} />

        <Route path="/updateregistration" element={<Updateregistration />} />
        <Route path="/viewregistration" element={<Viewregistration />} />
        <Route path="/addregistration" element={<Addregistration />} />
        <Route path="/oldregistration" element={<Oldstudent />} />
        <Route path="/pendingbal" element={<Pendingbal />} />
        <Route path="/viewsinglereg" element={<Viewsinglereg />} />
        <Route path="/editprofile" element={<Editprofile />} />
        <Route path="/viewbill" element={<Viewbill />} />
        <Route path="/addbill" element={<Addbill />} />
        <Route path="/printbill" element={<Printbill />} />

        <Route path="/updateproject" element={<Updateproject />} />
        <Route path="/viewproject" element={<Viewproject />} />
        <Route path="/addproject" element={<Addproject />} />
        <Route path="/" element={<Home />} />
        <Route path="/enquiry" element={<Enquiryentryforall />} />
        <Route path="/enquiryentry" element={<Enquiryentry />} />
        <Route path="/updatesubject" element={<Updatesubject />} />
        <Route path="/viewsubject" element={<Viewsubject />} />
        <Route path="/addsubject" element={<Addsubject />} />
        <Route path="/updatetopics" element={<Updatetopics />} />
        <Route path="/viewtopics" element={<Viewtopics />} />
        <Route path="/addtopics" element={<Addtopics />} />
        <Route path="/updatesubtopics" element={<Updatesubtopics />} />
        <Route path="/viewsubtopics" element={<Viewsubtopics />} />
        <Route path="/addsubtopics" element={<Addsubtopics />} />
        <Route path="/updatecontent" element={<Updatecontent />} />
        <Route path="/viewcontent" element={<Viewcontent />} />
        <Route path="/addcontent" element={<Addcontent />} />

        <Route path="/updatecourse" element={<Updatecoursecat />} />
        <Route path="/viewcourse" element={<Viewcoursecat />} />
        <Route path="/addcourse" element={<Addcoursecat />} />
        <Route path="/check" element={<Check />} />

        <Route path="/bulkquestion" element={<Bulkquestion />} />
        <Route path="/updatequestion" element={<Updatequestion />} />
        <Route path="/viewquestion" element={<Viewquestion />} />
        <Route path="/addquestion" element={<Addquestion />} />

        <Route path="c" element={<C />} />
        <Route path="cpp" element={<Cpp />} />
        <Route path="cs" element={<Cs />} />
        <Route path="datascience" element={<Datascience />} />
        <Route path="sqlserver" element={<Sqlserver />} />
        <Route path="java" element={<Java />} />
        <Route path="python" element={<Python />} />
        <Route path="html" element={<Html />} />
        <Route path="reactjs" element={<Reactjs />} />
        <Route path="mysql" element={<Mysql />} />
        <Route path="mongodb" element={<Mongodb />} />
        <Route path="javafullstack" element={<Javafullstack />} />
        <Route path="pythonwebdevelopment" element={<Pythonwebdevelopment />} />
        <Route path="javawebdevelopment" element={<Javawebdevelopment />} />
        <Route path="flask" element={<Flask />} />
        <Route path="django" element={<Django />} />
        <Route path="jsp" element={<Jsp />} />
        <Route path="springboot" element={<Springboot />} />
        <Route path="hibernate" element={<Hibernate />} />
        <Route path="selenium" element={<Selenium />} />
        <Route path="tallyprime" element={<Tallyprime />} />
        <Route path="advexcel" element={<Advexcel />} />
        <Route path="msoffice" element={<Msoffice />} />
        <Route path="ds" element={<Ds />} />

        <Route path="/updateexpense" element={<Updateexpense />} />
        <Route path="/viewexpense" element={<Viewexpense />} />
        <Route path="/addexpense" element={<Addexpense />} />

        <Route
          path="/updatemonthlyexpense"
          element={<Updatemonthlyexpense />}
        />
        <Route path="/viewmonthlyexpense" element={<Viewmonthlyexpense />} />
        <Route path="/addmonthlyexpense" element={<Addmonthlyexpense />} />

        <Route path="/updateproject2023" element={<Updateproject2023 />} />
        <Route path="/viewproject2023" element={<Viewproject2023 />} />

        <Route path="/viewPendingcommit" element={<Viewprojectcommit />} />

        <Route path="/addproject2023" element={<Addproject2023 />} />
        <Route path="/viewdailyreport" element={<Viewdailyreport />} />

        <Route
          path="/updateproject23payment"
          element={<Updateproject23payment />}
        />
        <Route
          path="/viewproject23payment"
          element={<Viewproject23payment />}
        />
        <Route path="/addproject23payment" element={<Addproject23payment />} />

        <Route
          path="/updateregistrationremark"
          element={<Updateregistrationremark />}
        />
        <Route
          path="/viewregistrationremark"
          element={<Viewregistrationremark />}
        />
        <Route
          path="/addregistrationremark"
          element={<Addregistrationremark />}
        />

        <Route path="/updateenquiryremark" element={<Updateenquiryremark />} />
        <Route path="/viewenquiryremark" element={<Viewenquiryremark />} />
        <Route path="/addenquiryremark" element={<Addenquiryremark />} />
        <Route path="/updatemobileenquiry" element={<Updatemobileenquiry />} />
        <Route path="/viewmobileenquiry" element={<Viewmobileenquiry />} />
        <Route path="/addmobileenquiry" element={<Addmobileenquiry />} />
        <Route path="/coursechange" element={<Coursechange />} />
      </Routes>
    </>
  );
};

export default App;
