import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import Nav from "../process/Nav";
import { url } from "../link";
const Viewproject = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [to, setto] = useState("");
  const [bal, setbal] = useState("");
  const [top, settop] = useState("");
  const [tow, settow] = useState("");
  const [toptow, settoptow] = useState("");
  const [count, setcount] = useState("");

  useEffect(() => {
    axios.post(url + "/edusphere/viewproject2023").then((response) => {
      setData(response.data);

      var to = 0;
      var bal = 0;
      var top = 0;
      var tow = 0;
      var toptow = 0;
      for (var x of response.data) {
        to = to + x[5];
        bal = bal + x[7];
        top = top + x[6];
        toptow = toptow + x[10];
        tow = tow + x[9];
      }
      setto(to);
      setbal(bal);
      settop(top);
      settow(tow);
      settoptow(toptow);
      $(document).ready(function () {
        var eventFired = function (type) {
          var n = $("#demo_info")[0];

          n.scrollTop = n.scrollHeight;
        };

        $("#example")
          .on("order.dt", function () {
            eventFired("Order");
          })
          .on("search.dt", function () {
            eventFired("Search");
          })
          .on("page.dt", function () {
            eventFired("Page");
          })
          .DataTable();
      });
    });
    axios.post(url + "/edusphere/getcomcount").then((res) => {
      setcount(res.data);
    });
  }, []);
  const viewproject = (e) => {
    nav("/addproject23payment", { state: [e[0], e[5], e[6], e[7]] });
  };
  const handleDownload = (e) => {
    // Replace 'your_file_url' with the actual URL of the file you want to download
    const fileUrl = e;

    // Create an anchor element
    const link = document.createElement("a");
    link.href = fileUrl;

    // Specify the filename for the downloaded file
    link.download = "downloaded_file.txt";

    // Trigger a click on the link to initiate the download
    document.body.appendChild(link);
    link.click();

    // Remove the link from the DOM
    document.body.removeChild(link);
  };
  const deletec = (e) => {
    nav("/updateproject2023", { state: e });
  };

  return (
    <div>
      <Nav />
      <div className="main">
        <div className="page-container">
          <h5>
            Total {to} TotalPaid {top} Totalbal {bal}
            Totaltovendor {tow} <br /> Totaltopaid {toptow} Balance{" "}
            {top - toptow}
          </h5>
          <button
            className="btn btn-primary"
            onClick={() => {
              nav("/viewdailyreport");
            }}
          >
            View daily payment
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              nav("/viewPendingcommit");
            }}
          >
            Pending Commitments <span className="badge bg-danger">{count}</span>
          </button>
          <div className="table-responsive p-0 pb-2">
            <div
              id="demo_info"
              className="box"
              style={{ display: "none" }}
            ></div>
            <table id="example" className="display" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>pid</th>
                  <th>name</th>
                  <th>phone_number</th>
                  <th>college</th>
                  <th>project_title</th>
                  <th>total_amount</th>
                  <th>total_paid</th>
                  <th>balance</th>
                  <th>vendor</th>
                  <th>total</th>
                  <th>paid</th>
                  <th>Base</th>
                  <th>Report</th>
                  <th>Projectfile</th>
                  <th>Ppt</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map((d) => {
                  return (
                    <tr key={d[0]}>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => viewproject(d)}
                        >
                          {d[0]}
                        </button>
                      </td>
                      <td>{d[1]}</td>
                      <td>{d[2]}</td>
                      <td>{d[3]}</td>
                      <td>{d[4]}</td>
                      <td>{d[5]}</td>
                      <td>{d[6]}</td>
                      <td>{d[7]}</td>
                      <td>{d[8]}</td>
                      <td>{d[9]}</td>
                      <td>{d[10]}</td>
                      <td>
                        {d[11] !== "" ? (
                          <button
                            className="btn btn-success"
                            onClick={() => {
                              handleDownload(
                                url + "/static/project2023/" + d[11]
                              );
                            }}
                          >
                            base
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        {d[12] !== "" ? (
                          <button
                            className="btn btn-success"
                            onClick={() => {
                              handleDownload(
                                url + "/static/project2023/" + d[12]
                              );
                            }}
                          >
                            Report
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        {d[13] !== "" ? (
                          <button
                            className="btn btn-success"
                            onClick={() => {
                              handleDownload(
                                url + "/static/project2023/" + d[13]
                              );
                            }}
                          >
                            Projectfile
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        {d[14] !== "" ? (
                          <button
                            className="btn btn-success"
                            onClick={() => {
                              handleDownload(
                                url + "/static/project2023/" + d[14]
                              );
                            }}
                          >
                            Ppt
                          </button>
                        ) : (
                          ""
                        )}
                      </td>

                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            nav("/viewproject23payment", { state: d[0] });
                          }}
                        >
                          View
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => deletec(d)}
                        >
                          Edit
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            nav("/addprojectcommentment", { state: d[0] });
                          }}
                        >
                          Add Commitment
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            nav("/viewprojectcommentment", { state: d[0] });
                          }}
                        >
                          View Commitment
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            nav("/addlaptop", { state: d[0] });
                          }}
                        >
                          Add Laptop
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Viewproject;
